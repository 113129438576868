import image from '../../assets/images';
import { Loading } from '../../component';

function ChartTrack({ loading, logo, textTrack }) {
  return (
    <div className="card sale-card bg-white-l">
      {loading && <Loading />}
      <div className="card-body pos-relative">
        <div className="card-header" style={{ display: 'flex' }}>
          <img src={image[logo]} className="img-30 m-r-5" alt="Logo" />
          <h6 style={{ fontWeight: 700 }}>{textTrack}</h6>
        </div>
        <div className="card-block">
          <div id="deal-analytic-chart" className="chart-shadow" style={{ height: '350px' }}></div>
        </div>
      </div>
    </div>
  );
}

ChartTrack.displayName = 'ChartTrack';

export default ChartTrack;
